import { Footer } from '@digi-tim-19/components';
import * as React from 'react';
import { siteImages } from '../../assets/images/index';

export const SiteFooter = () => (
  <Footer
    config={{
      sections: [
        {
          title: 'PROCESSOS',
          linkTitle: '#',
          subItems: [
            {
              name: 'Suporte',
              link: '#'
            },
            {
              name: 'Venda',
              link: '#'
            },
            {
              name: 'Tratamento de Pedidos',
              link: '#'
            },
            {
              name: 'Aparelhos',
              link: '#'
            },
            {
              name: 'Portifólio de Aparelhos',
              link: '#'
            }
          ]
        },
        {
          title: 'PLANOS, OFERTAS E SERVIÇOS',
          linkTitle: '#',
          subItems: [
            {
              name: 'Planos',
              link: '#'
            },
            {
              name: 'Serviços',
              link: '#'
            },
            {
              name: 'Ofertas',
              link: '#'
            },
            {
              name: 'Materiais de Apoio',
              link: '#'
            },
            {
              name: 'Campanhas de Incentivo',
              link: '#'
            }
          ]
        },
        {
          title: 'CORP PLAY',
          linkTitle: '#',
          subItems: [
            {
              name: 'Destaques',
              link: '#'
            },
            {
              name: 'Mais Vídeos',
              link: '#'
            },
            {
              name: 'Sistema TIM',
              link: '#'
            },
            {
              name: 'Radar',
              link: '#'
            },
            {
              name: 'Portas Abertas',
              link: '#'
            }
          ]
        },
        {
          title: 'COMUNICAÇÃO',
          linkTitle: '#',
          subItems: [
            {
              name: 'Noticias',
              link: '#'
            },
            {
              name: 'Boletins Sempre Juntos',
              link: '#'
            },
            {
              name: 'Materiais de Apoio',
              link: '#'
            },
            {
              name: 'Treinamentos',
              link: '#'
            },
            {
              name: 'Treinamentos',
              link: '#'
            },
            {
              name: 'Administração',
              link: '#'
            },
            {
              name: 'Administração do Site',
              link: '#'
            }
          ]
        },
        {
          title: 'ACESSE NOSSAS PLATAFORMAS',
          linkTitle: '#',
          showItemsMobile: true,
          subItems: [
            {
              name: 'Connect',
              link: '#'
            },
            {
              name: 'Conexão TIM',
              link: '#'
            }
          ],
          mobileStores: [
            {
              img: siteImages.googleStore,
              link: 'https://play.google.com/store/apps/details?id=br.com.timbrasil.meutim',
              alt: 'Tim'
            },
            {
              img: siteImages.appleStore,
              link: 'https://play.google.com/store/apps/details?id=br.com.timbrasil.meutim',
              alt: 'Tim'
            }
          ]
        }
      ]
    }}
    timLogo={siteImages.tim_rodape}
    socialMedia={{
      linkTitle: '#',
      title: 'SIGA-NOS NAS REDES SOCIAIS',
      medias: [
        {
          img: siteImages.iconFacebook,
          alt: 'Facebook',
          link: 'https://facebook.com'
        },
        {
          img: siteImages.iconTwitter,
          alt: 'Twitter',
          link: 'https://facebook.com'
        },
        {
          img: siteImages.iconLinkedin,
          alt: 'LinkedIn',
          link: 'https://facebook.com'
        },
        {
          img: siteImages.iconInstagram,
          alt: 'Instagram',
          link: 'https://facebook.com'
        },
        {
          img: siteImages.iconYoutube,
          alt: 'Youtube',
          link: 'https://facebook.com'
        }
      ],
      phone: '0800 777 4141',
      description:
        'SEGUNDA À SEXTA \n 9:00 ÀS 13:00 - 14:00 ÀS 18:00'
    }}
  />
);
